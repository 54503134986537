<template>
  <div class="userCollect">
    <div class="userNewsLeft">
      <ul>
        <li :class="{ active: tabindex == 1 }" @click="clickActive(1)">
          优惠券
        </li>
        <li :class="{ active: tabindex == 2 }" @click="clickActive(2)">
          兑换码
        </li>
      </ul>
    </div>
    <!-- 作业记录 -->
    <div class="list userNewsRight" v-if="tabindex == 1">
      <div class="video_list coupon_content clearfix">
        <div class="h2"><span>我的优惠券</span></div>
        <!-- v-if="coupon_list.length > 0" -->
        <div class="loading" v-show="loading">
          正在加载中
          <img src="../../assets/images/jiazai.png" alt="" />
        </div>
        <ul>
          <li v-for="(item, index) of coupon_list" :key="index">
            <div class="price">
              <span>￥</span><span>{{ item.amount }}</span>
            </div>
            <div class="coupon_name">
              <div>{{ item.title }}</div>
              <div>有效期至：{{ item.lifespan }}</div>
            </div>
            <div class="cut"></div>
            <div
              class="coupon_use"
              v-if="item.is_use == 0"
              @click="useCouponFun(item.type)"
            >
              立即使用
            </div>
            <div class="coupon_use2" v-else>
              {{ item.is_use == 1 ? "已使用" : "已过期" }}
            </div>
          </li>
        </ul>
      </div>
      <el-empty
        v-if="(!coupon_list || coupon_list.length <= 0) && !loading"
        description="暂无数据"
      ></el-empty>
      <!-- <page v-if="total > 12" :page="page" :limit="limit" :total="total"></page> -->
    </div>

    <!-- 奖品列表 -->
    <div class="list userNewsRight" v-if="tabindex == 2">
      <div class="video_list clearfix">
        <div class="h2">
          <span>兑换记录</span><a @click="exchangeShowHide">点击兑换></a>
        </div>
        <div class="loading" v-show="loading">
          正在加载中
          <img src="../../assets/images/jiazai.png" alt="" />
        </div>
        <div v-if="redeem_list.length > 0">
          <ul class="userOrder_table">
            <li>订单名称</li>
            <li>兑换码</li>
            <li>兑换时间</li>
          </ul>
          <div
            class="record_one"
            v-for="(item, index) of redeem_list"
            :key="index"
          >
            <div>{{ item.title }}</div>
            <div>{{ item.code_number }}</div>
            <div>{{ item.create_time }}</div>
          </div>
        </div>

        <div
          class="empty_btn"
          v-else-if="
            (!redeem_list || redeem_list.length == 0) && loading == false
          "
          @click="exchangeShowHide"
        >
          点击兑换
        </div>
      </div>

      <!-- <page v-if="total > 12" :page="page" :limit="limit" :total="total"></page> -->
    </div>
    <div class="bg" v-if="exchange_type || tipStatus"></div>
    <div class="coupon_wrap" v-if="exchange_type">
      <div class="coupon">
        <h2>兑换码</h2>
        <div class="coupon_content">
          <p>请输入兑换码</p>
          <input type="text" v-model="code" placeholder="请输入兑换码" />
          <el-button type="primary" @click="redeemFun">确定</el-button>
        </div>
        <div class="close" @click="exchangeShowHide">
          <img src="../../assets/images/close.svg" />
        </div>
      </div>
    </div>
    <div class="tips_wrap" v-show="tipStatus">
      <div class="tips">
        <div class="tips_thumb">
          <img
            src="../../assets/images/redeem_success1.png"
            v-if="site_id == 6"
          />
          <img
            src="../../assets/images/redeem_success2.png"
            v-if="site_id == 10"
          />
        </div>
        <div class="tips_h2">恭喜，兑换成功</div>
        <div class="tips_p">可到我的主页查看</div>

        <div class="close" @click="tipStatusFun">
          <img src="../../assets/images/close.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { WOW } from 'wowjs'
import page from "../../components/page.vue";
export default {
  name: "userCollect",
  //   components: {
  //     page,
  //   },
  data() {
    return {
      // 全部作业
      coupon_list: [],
      // 奖品列表
      redeem_list: [],

      // 分页
      total: 0, //总数
      page: 1, //页数
      limit: 12, //每页个数

      // 收藏参数
      type: "", //类型

      is_benren: 0,

      tabindex: 1,
      exchange_type: false,
      loading: true,
      tipStatus: false,
      site_id: 6,
    };
  },
  mounted() {
    this.$parent.tab = 2;
    this.$parent.tabIndex = 8;
    this.$parent.$parent.routerIndex = 0;
    this.$parent.$parent.showIndex = 1;

    if (this.tabindex == 2) {
      this.RecordFun();
    } else {
      this.couponListFun();
    }
    this.getUser();
  },
  methods: {
    //获取用户信息
    getUser() {
      var that = this;
      this.$api.POST(
        this.$face.memberInfo,
        {
          user_id: this.$route.query.userId,
        },
        function (res) {
          that.is_benren = res.data.is_benren;
          console.log(that.is_benren);
          // localStorage.setItem('is_benren', res.data.is_benren);
          // if (res.data.is_benren != 1) {
          // 	localStorage.setItem('userInfo_other', JSON.stringify(res.data))
          // }
          // this.$router.push({
          //   name: "index",
          // });
        }
      );
    },
    tipStatusFun() {
      this.$router.push({
        name: "index",
      });
    },
    // 优惠券
    couponListFun() {
      var that = this;
      this.$api.POST(
        this.$face.myCoupon,
        {
          status: 0,
          page: this.page,
          limit: this.limit,
        },
        function (res) {
          that.coupon_list = res.data;
          that.loading = false;
          console.log(that.coupon_list);
        }
      );
    },
    redeemFun() {
      this.$api.POST(
        this.$face.redeem,
        {
          code_number: this.code,
        },
        (res) => {
          this.loading = false;
          this.exchange_type = false;
          this.tipStatus = true;
          this.code = "";
          this.site_id = res.data.site_id;
          this.RecordFun();
          this.$parent.getUser();
          this.$parent.$parent.getUser();
        }
      );
    },
    RecordFun() {
      var that = this;
      this.$api.POST(this.$face.redeemRecord, {}, function (res) {
        that.loading = false;
        that.redeem_list = res.data;
      });
    },
    exchangeShowHide() {
      this.exchange_type = !this.exchange_type;
    },
    useCouponFun(type) {
      localStorage.setItem("type", type);
      let routeData = this.$router.resolve({
        name: "vip",
      });
      window.open(routeData.href, "_blank");
    },
    // 左侧导航选中
    clickActive(type) {
      console.log("type", type);
      this.tabindex = type;
      this.page = 1;

      if (type == 1) {
        if (this.coupon_list.length == 0) {
          this.loading = true;
        }
        this.couponListFun();
      } else if (type == 2) {
        if (this.redeem_list.length == 0) {
          this.loading = true;
        }
        this.RecordFun();
      }
    },
  },
};
</script>

<style scoped>
.userNewsRight_one {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #f5f5f5;
}

.order_content {
  width: 195px;
  height: 100%;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  /* overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap; */
}
.order_content div {
  cursor: pointer;
}

.news_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0;
  box-sizing: border-box;
}
.userOrder_table li {
  width: 195px;
  font-size: 14px;
  color: #333333;
  line-height: 60px;
  text-align: center;
}
.userOrder_table li:nth-child(1) {
  width: 310px;
}

.userOrder_table li:nth-child(2) {
  width: 240px;
}
.userOrder_table li:nth-child(3) {
  width: 185px;
}
.userOrder_table {
  width: 1025px;
  height: 60px;
  background: #f4f6f6;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  margin-top: 25px;
  margin: 0 auto 20px;
}
.record_one {
  width: 1025px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
}
.record_one div {
  text-align: center;
}
.record_one div:nth-child(1) {
  width: 310px;
}

.record_one div:nth-child(2) {
  width: 240px;
}
.record_one div:nth-child(3) {
  width: 185px;
}

.userOrder_table_two li {
  padding-left: 30px;
}

.userNewsLeft {
  background-color: #fff;
  border-radius: 10px;
  width: 15%;
  float: left;
}

.userNewsLeft ul {
  padding: 37px 0 0;
  overflow: hidden;
}

.userNewsLeft li {
  font-size: 16px;
  color: #333;
  padding-left: 37px;
  margin-bottom: 37px;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  position: relative;
}

.userNewsLeft li::before {
  display: block;
  width: 6px;
  height: 20px;
  content: " ";
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
}

.userNewsLeft li.active::before {
  background-color: #5957ff;
}

.userNewsLeft li.active {
  color: #5957ff;
}

.userNewsRight {
  width: 78%;
  background: #fff;
  border-radius: 10px;
  float: right;
  padding: 15px 2% 0;
  min-height: 500px;
  position: relative;
}

.userCollect {
  padding-top: 26px;
  height: auto;
  overflow: hidden;
  padding-bottom: 26px;
}

.list {
  padding-bottom: 99px;
}

/* 视频教程 */
.video_list {
  margin: 0 -13.33px 24px;
}

.userFavorite_li_del {
  width: 43px;
  height: 26px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 15px;
  right: 16px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.write {
  color: #5957ff;
}
.userCollect >>> .el-drawer {
  height: 100% !important;
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0px rgb(0 0 0 / 0%),
    0 0px 0px 0px rgb(0 0 0 / 0%) !important;
}
.coupon_content {
  /* padding: 10px 0; */
}
.video_list .h2 {
  font-size: 18px;
  width: 1028px;
  color: #8b8b8b;
  height: 42px;
  line-height: 42px;
  border-bottom: 1px #f2f2f2 solid;
  margin: 10px auto 35px;
}
.video_list .h2 span {
  float: left;
}
.video_list .h2 a {
  float: right;
  color: #6362ff;
  font-size: 16px;
  cursor: pointer;
}
.coupon_content ul {
  /* overflow-y: auto;
  height: 180px; */
}
.coupon_content li {
  width: 1012px;
  height: 114px;
  border: 1px #e4e5e5 solid;
  border-radius: 10px;
  margin: auto;
  position: relative;
  margin-bottom: 20px;
}
.coupon_content li:last-child {
  margin-bottom: 0;
}
.coupon_content li::before {
  content: " ";
  width: 11px;
  height: 114px;
  background: #fce9d9;
  position: absolute;
  left: 0;
}
.price {
  float: left;
  margin-left: 30px;
  margin-top: 37px;
  color: red;
  margin-right: 33px;
}
.price span:nth-child(1) {
  font-size: 18px;
}
.price span:nth-child(2) {
  font-size: 39px;
}
.coupon_name {
  margin-top: 37px;
  float: left;
}
.coupon_name div:first-child {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #595757;
  text-align: left;
}
.coupon_name div:last-child {
  font-size: 12px;
  color: #8f8f8f;
}
.cut {
  position: absolute;
  left: 775px;
  top: -1px;
  width: 20px;
  height: 116px;
  background: url("../../assets/images/vip/cut2.png") no-repeat #fff;
}
.coupon_use {
  width: 127px;
  height: 39px;
  background: #fcebdd;
  text-align: center;
  line-height: 39px;
  font-size: 16px;
  border-radius: 50px;
  float: right;
  margin-top: 37px;
  margin-right: 45px;
  color: #ff3828;
  cursor: pointer;
  font-weight: bold;
}
.coupon_use2 {
  width: 127px;
  height: 39px;
  background: #f4f6f6;
  text-align: center;
  line-height: 39px;
  font-size: 16px;
  border-radius: 50px;
  float: right;
  margin-top: 37px;
  margin-right: 45px;
  color: #333;
  cursor: pointer;
}
.coupon_content >>> .el-empty__image {
  width: 60px;
}
.empty_btn {
  width: 170px;
  height: 52px;
  background-color: #5857ff;
  border-radius: 8px;
  text-align: center;
  line-height: 52px;
  color: #fff;
  font-size: 16px;
  margin: 130px auto 0;
  cursor: pointer;
}
.bg {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: 90;
}
.coupon_wrap {
  width: 545px;
  height: 288px;
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 91;
}
.coupon {
  position: relative;
}
.coupon h2 {
  height: 51px;
  line-height: 51px;
  font-size: 16px;
  font-weight: bold;
  color: #5957ff;
  text-align: center;
  border-bottom: 1px #e4e5e5 solid;
}
.close {
  position: absolute;
  top: 17px;
  right: 22px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: 0.6;
}
.close img {
  width: 16px;
  height: 16px;
}
.coupon_content {
  padding: 30px 0;
  text-align: center;
}
.coupon_content p {
  font-size: 16px;
  color: #909090;
}
.coupon_content input {
  width: 155px;
  height: 42px;
  display: block;
  margin: 19px auto 28px;
  border: 1px #e4e5e5 solid;
  border-radius: 8px;
  padding: 0 15px;
  transition: 0.3s;
  text-align: center;
}
.coupon_content input:focus {
  border-color: #5857ff;
}
.coupon_content input::-ms-input-placeholder {
  text-align: center;
}
.coupon_content input::-webkit-input-placeholder {
  text-align: center;
}
.coupon_content >>> .el-button--primary {
  width: 119px;
  height: 36px;
  background-color: #5857ff;
  line-height: 1;
}
.loading {
  width: 100%;
  height: 800px;
  text-align: center;
  font-size: 20px;
  color: #999999;
  text-align: center;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1;
  padding-top: 100px;
  box-sizing: border-box;
}

.loading img {
  width: 24px;
  height: 24px;
  display: inline-block !important;
  vertical-align: middle;
  animation: fadeum 5s infinite;
}
.tips_wrap {
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 91;
  width: 545px;
  height: 288px;
}
.tips {
  position: relative;
}
.tips_thumb {
  padding-top: 40px;
  width: 495rpx;
  margin: auto;
}
.tips_thumb img {
  display: block;
  margin: auto;
}
.tips_h2 {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #595757;
  padding: 27px 0 10px;
}
.tips_p {
  font-size: 14px;
  color: #5e5c5c;
  text-align: center;
}
.close {
  position: absolute;
  right: 22px;
  top: 20px;
}
.close img {
  width: 15px;
  height: 15px;
  opacity: 0.6;
}
</style>
